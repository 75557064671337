import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getEventContent = createAsyncThunk(
  "/event/content",
  async (page) => {
    const response = await axios.get(`/get_all_events?page=${page}`, {});

    return response.data;
  }
);

export const deleteEventContent = createAsyncThunk(
  "/deleteEvent/content",
  async (id) => {
    const response = await axios.delete(`/delete_single_event/${id}`);
    return response.data;
  }
);

export const getArtist = createAsyncThunk(
  "/getArtist/content",
  async ({ search, page }) => {
    console.log("page", page ? page : 1);
    const response = await axios.get(
      `/artists?search=${search || ""}&page=${page || 1}`
    );
    return response.data;
  }
);

export const getGenres = createAsyncThunk("/getGenre/content", async () => {
  const response = await axios.get(`/get_all_genres`);
  return response.data;
});

export const eventSlice = createSlice({
  name: "event",
  initialState: {
    isLoading: false,
    event: [],
    artist: [],
    genre : []
  },
  reducers: {
    // addNewLead: (state, action) => {
    //     let {newLeadObj} = action.payload
    //     state.leads = [...state.leads, newLeadObj]
    // },

    deleteLead: (state, action) => {
      const indexToRemove = state.event.events.findIndex(
        (item) => item._id === action.payload
      );
      console.log(indexToRemove, "index000");
      if (indexToRemove !== -1) {
        state.event.events.splice(indexToRemove, 1);
      }
    },
  },

  extraReducers: {
    [getEventContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getEventContent.fulfilled]: (state, action) => {
      state.event = action.payload;
      state.isLoading = false;
    },
    [getEventContent.rejected]: (state) => {
      state.isLoading = false;
    },

    [getArtist.pending]: (state) => {
      state.isLoading = true;
    },
    [getArtist.fulfilled]: (state, action) => {
      state.artist = action.payload;
      state.isLoading = false;
    },
    [getArtist.rejected]: (state) => {
      state.isLoading = false;
    },

    [getGenres.pending]: (state) => {
      state.isLoading = true;
    },
    [getGenres.fulfilled]: (state, action) => {
      console.log(action)
      state.genre = action.payload;
      state.isLoading = false;
    },
    [getGenres.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewLead, deleteLead } = eventSlice.actions;

export default eventSlice.reducer;

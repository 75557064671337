import axios from "axios";

const initializeApp = () => {
  // process.env.REACT_APP_BASE_URL_LIVE;

  // Setting base URL for all API request via axios
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

  axios.defaults.baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL_LIVE
      : process.env.REACT_APP_BASE_URL_LOCAL;
};

export default initializeApp;

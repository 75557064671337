import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getRecordingContent = createAsyncThunk(
  "/recording/content",
  async (page) => {
    const response = await axios.get(
      `/get_all_events_recordings?page=${page}`,
      {}
    );
    console.log(response, "responce");
    return response.data;
  }
);

export const deleteRecordingContent = createAsyncThunk(
  "deleteEvent/content",
  async (id) => {
    const response = await axios.delete(`/recording?recording_id=${id}`);

    return response.data;
  }
);

export const recordingSlice = createSlice({
  name: "recording",
  initialState: {
    isLoading: false,
    recording: [],
  },
  reducers: {
    deleteRecording: (state, action) => {
      const indexToRemove = state.recording.recordings.findIndex(
        (item) => item.stream_id == action.payload
      );

      if (indexToRemove !== -1) {
        state.recording.recordings.splice(indexToRemove, 1);
      }
    },
  },

  extraReducers: {
    [getRecordingContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getRecordingContent.fulfilled]: (state, action) => {
      state.recording = action.payload;
      state.isLoading = false;
    },
    [getRecordingContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { deleteRecording } = recordingSlice.actions;

export default recordingSlice.reducer;
